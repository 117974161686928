import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_MORE_URL,
  GAMES_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  MY_BETS_BASE_URL,
  SEARCH_BASE_URL,
  SPORT_BASE_URL
} from 'constants/locations';
import { IRunnersStatisticsMap, ITimeFormHistoryColumns } from 'types/markets';

export const MOBILE_MAX_DISPLAY_SIZE = 999;
export const NEXT_RACE_COUNT = 4;
export const POPULAR_MARKET_MIN_COUNT = 2;
export const POPULAR_MARKET_MAX_COUNT = 4;
export const RACE_UPDATE_INTERVAL = 15000;
export const BALANCE_UPDATE_INTERVAL = 10000;
export const AUTO_CASH_OUT_GET_MARKETS_INTERVAL = 3000;
export const SOCCER_PERIOD_TIME = 45;
export const SOCCER_EXTRA_PERIOD_TIME = 15;
export const SOCCER_NUMBER_OF_PENALTIES = 5;

export const IOS_BUNDLE = 'IOS_BUNDLE';

export const BIAB_CUSTOM_POPUP = 'BIAB_CUSTOM_POPUP';
export const PREV_PAGE_BEFORE_LOGIN = 'PREV_PAGE_BEFORE_LOGIN';

export const OPEN_ALL_SPORT = 'OPEN_ALL_SPORT';

export const SportIds = {
  SOCCER: '1',
  TENNIS: '2',
  CRICKET: '4',
  HORSE_RACING: '7',
  TODAY_HORSE_RACING: 'tc-7',
  GRAY_HOUND_RACING: '4339',
  TODAY_GRAY_HOUND_RACING: 'tc-4339',
  BASKETBALL: '7522',
  GOLF: '3'
} as const;

export const SPORTS_NAME: Record<string, string> = {
  [SportIds.HORSE_RACING]: 'horseRacing',
  [SportIds.GRAY_HOUND_RACING]: 'greyhoundRacing'
};

export const BETTING_TYPES = {
  asianHandicapSingleLine: 'ASIAN_HANDICAP_SINGLE_LINE',
  asianHandicapDoubleLine: 'ASIAN_HANDICAP_DOUBLE_LINE',
  line: 'LINE',
  odds: 'ODDS',
  altTotalGoals: 'ALT_TOTAL_GOALS',
  anyNumberOfWinners: 'ANY_NUMBER_OF_WINNERS'
};

export const FAVORITES_TYPES: Record<string, string> = {
  event: 'EVENT',
  market: 'MARKET',
  sport: 'EVENT_TYPE',
  country: 'COUNTRY',
  competition: 'COMPETITION',
  group: 'GROUP'
};

export const RACING_SPORT: Record<string, boolean> = {
  [SportIds.HORSE_RACING]: true,
  [SportIds.GRAY_HOUND_RACING]: true
};

export const RACING_SPORT_WITH_TODAY: Record<string, boolean> = {
  [SportIds.HORSE_RACING]: true,
  [SportIds.GRAY_HOUND_RACING]: true,
  [SportIds.TODAY_GRAY_HOUND_RACING]: true,
  [SportIds.TODAY_HORSE_RACING]: true
};

export const IS_SPECIAL_HEADER_ON_EVENT: Record<string, boolean> = {
  [SportIds.TENNIS]: true,
  [SportIds.SOCCER]: true,
  [SportIds.CRICKET]: true,
  [SportIds.BASKETBALL]: true
};

export const ROUTER_BASE_URL = window.environmentConfig?.rootPath || '/customer';

export const APPLICATION_TYPE_WEB = 'WEB';

export const EXCHANGE = 'EXCHANGE';
export const GAME = 'GAME';

export const ONLY_MOBILE_URLS = [
  ACCOUNT_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  SPORT_BASE_URL,
  SEARCH_BASE_URL,
  `${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_MORE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  `${ASIAN_BASE_URL}${MOBILE_LOGIN_BASE_URL}`,
  `${GAMES_BASE_URL}${MOBILE_LOGIN_BASE_URL}`,
  `${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}`,
  `${GAMES_BASE_URL}${ACCOUNT_BASE_URL}`
];

export const TIME_FILTERS = ['all', 'in_play', 'today', 'tomorrow', 'future'];

export const PRECISE_LEN = Math.pow(10, 3);

export const TODAY_CARD_PREFIX = 'tc-';
export const MOBILE_LIST_VIEW_MAX_TABS = 5;
export const LIST_VIEW_TAB_WIDTH = 120;
export const LONG_MARKETS_SCROLL_NUMBER = 10;
export const LANDSCAPE_MIN_WIDTH = 480;
export const COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH = 1280;

export const MAX_PRICE = 1000;
export const MIN_PRICE = 1.01;

export const ALL_MARKETS_TAB_NAME = 'All Markets';
export const IS_SCROLLING_SECTION = 'scrolling';

export const CSRF_TOKEN_URLS =
  '.*/api/currentBets|.*/api/account/info|.*/api/account/statement.*|.*/api/myBets.*|.*/api/total/profit.*';

export const TIME_FORM_RUNNER_DETAILS: Array<keyof IRunnersStatisticsMap> = [
  'form',
  'trainer',
  'owner',
  'age',
  'weight',
  'officialRating',
  'pedigree',
  'daysSinceLastRace',
  'jockeyClaim',
  'gear'
];

export const TIME_FORM_KEY_GOING = 'going';
export const TIME_FORM_HISTORY_MAX_COUNT = 6;
export const TIME_FORM_HISTORY_MOBILE_MAX_COLUMNS = 6;

export const TIME_FORM_HISTORY_COLUMNS: ITimeFormHistoryColumns[] = [
  { key: 'date', label: 'timeform.date', isShort: true },
  { key: 'course', label: 'timeform.course', isShort: true },
  { key: 'distance', label: 'timeform.distance', isShort: true },
  { key: 'going', label: 'timeform.going', isShort: true },
  { key: 'pos', label: 'timeform.pos', isShort: true },
  { key: 'type', label: 'timeform.type', isShort: true },
  { key: 'or', label: 'timeform.OR', isShort: false },
  { key: 'bsp', label: 'timeform.BSP', isShort: false },
  { key: 'ipHiLo', label: 'timeform.IPHiLo', isShort: false },
  { key: 'jockey', label: 'timeform.jockey', isShort: false }
];

export const PL_TABLE_HEADER = ['4+', '4', '3', '2', '1', '1', '2', '3', '4', '4+'];

export const MOBILE_PL_TABLE_HEADER = ['4+', '4', '3', '2', '1', '', '1', '2', '3', '4', '4+'];

export const DEFAULT_CURRENCY = 'USD';
export const BASKETBALL_NORMAL_MATCH = 4;

export const PopupTypes = {
  FAVORITE: 'favorite',
  LOGIN_POPUP: 'loginPopup',
  TWO_FACTOR_AUTH: 'twoFactorAuth'
} as const;

export const PostMessageTypes = {
  USER_ACTIVITY: 'userActivity',
  LOGIN: 'evLoggedIn',
  LOGOUT: 'loggedOut',
  MY_BETS: 'myBets',
  APP_LOADED: 'evLoaded',
  ROUTE_CHANGE: 'routeChange',
  DAY_LIMIT: 'dayLimitExceed',
  LOGIN_TO_PLACE_BET: 'loginToPlaceBet',
  HEIGHT_CHANGE: 'heightChange',
  ACCOUNT_BETTING_PAGE_SCROLL_TO_TOP: 'accountBettingPageScrollToTop'
} as const;

export const NavigationItemTypes = {
  MY_BETS: 'myBets'
} as const;

export const USER_ACTIVITY_TIMEOUT = 60000;
export const TFA_ACTIVATION_TIMEOUT = 60000;
export const TFA_CODE_LENGTH = 6;

export const tooltipStatus: Record<string, string> = {
  SUSPENDED: 'tooltip.suspendedStatus',
  CLOSED: 'tooltip.closedStatus'
};

export const KEY_NAME_ESC = 'Escape';
export const KEY_EVENT_TYPE = 'keyup';

export const BroadCastHandledTypes = {
  LOGOUT: 'LOGOUT'
} as const;

export const BroadCastChannels = {
  USER_ACTIONS: 'USER_ACTIONS'
} as const;

export const GAME_BETTING = 'Game Betting';

export const SET = 'set';

export const CASHOUT_HEADER_HEIGHT = 47;

export const OPERATOR = {
  POKER_STAR: 'ps',
  AB: 'ab',
  ORBIT: 'orbit',
  EWL: 'ewl',
  FUN: 'FUN',
  DEFAULT: 'DEFAULT',
  A2F: 'a2f',
  NEXTSPRINT: 'nextsprint'
};

export const EXTRA_SMALL_SCREE_SIZE = 370;

export const SLICES_NAMES = {
  RACING: '@racing',
  MARKETS_PRICES: '@marketsPrices',
  EVENT: '@event',
  LIVE_SCORE: '@liveScore',
  POPULAR_MARKETS: '@popularMarkets',
  WHAT_IF: '@whatIf',
  ASIAN_SPORTS: '@asianSports',
  ASIAN_VIEW: '@asianView',
  ASIAN_VIEW_BETSLIP: '@asianViewBetslip',
  ASIAN_VIEW_QUICK_BETTING: '@asianViewQuickBetting',
  ASIAN_VIEW_MOBILE_BETSLIP: '@asianViewMobileBetslip',
  ASIAN_VIEW_SEARCH: '@asianViewSearch',
  ASIAN_VIEW_WIDGETS: '@asianViewWidgets',
  CURRENT_BETS: '@currentBets',
  ASIAN_CURRENT_BETS: '@asianCurrentBets',
  APP_CONFIGS: '@appConfigs',
  APP_SETTINGS: '@appSettings',
  AUTH: '@auth',
  ASIAN_VIEW_FAVOURITES: '@asianViewFavourites',
  OBSERVER: '@observer',
  TOOLTIP: '@tooltip',
  CASH_OUT_COUNTER: '@cashOutCounter',
  BANNERS: '@banners',
  COUPON_SETTINGS: '@couponSettings',
  ASIAN_VIEW_LEAGUES_FILTER: '@asianViewLeaguesFilter',
  ASIAN_VIEW_POPULAR_LINKS: '@asianViewPopularLinks',
  LOGIN_POP_UP: '@loginPopUp',
  COLLAPSE: '@collapse',
  ASIAN_VIEW_BET_SLIP_CASH_OUT: '@asianViewBetSlipCashOut',
  EVENT_CASH_OUT: '@eventCashOut',
  BACKEND_PAGE: '@backendPage',
  USER: '@user',
  RESPONSIBLE_BETTING: '@responsibleBetting',
  MARKET: '@market',
  MULTI_MARKET: '@multiMarket',
  BET_SLIP: '@betSlip',
  PLACEMENT: '@placement',
  INLINE_PLACEMENT: '@inlinePlacement',
  CASH_OUT: '@cashOut',
  COMPETITIONS: '@competitions',
  FEATURED_MARKETS: '@featuredMarkets',
  NEXT_RACES: '@nextRaces',
  PL_TABLE: '@plTable',
  GAMES: '@games',
  CANCEL_ACTIONS: '@cancelActions',
  MY_BETS: '@myBets',
  WEB_SOCKET: '@webSocket',
  HOME: '@home',
  IN_PLAY: '@inPlay',
  SEARCH: '@search',
  SPORTS: '@sports',
  TOURS: '@tours',
  LIST_VIEW_TABS: '@listViewTabs',
  FAVOURITES: '@favourites',
  META: '@meta',
  VIDEO_STREAM: '@videoStream',
  POPULAR: '@popular',
  POKER_STAR: '@pokerStar',
  FANCY_MARKET: '@fancyMarket',
  SPORT_PAGE: '@sportPage',
  PLACEMENT_MESSAGE: '@placementMessage',
  TOP_5_VIEW: '@top5View',
  BETS_STATUSES: '@betsStatuses',
  PROFIT_LOSS_TABLE_POP_UP: '@profitLossTablePopUp'
} as const;

export const ASIAN_TABS_EARLY_PREFIX = 'early';
export const ASIAN_TABS_TODAY_PREFIX = 'today';
export const ASIAN_TABS_LIVE_PREFIX = 'live';
export const ASIAN_TABS = [
  {
    title: 'Early',
    key: ASIAN_TABS_EARLY_PREFIX
  },
  {
    title: 'Today',
    key: ASIAN_TABS_TODAY_PREFIX
  },
  {
    title: 'Live',
    key: ASIAN_TABS_LIVE_PREFIX
  }
];
export const IS_VISITED_APP_SESSION_STORAGE_KEY = 'IS_VISITED_APP';
export const MAX_DOUBLE_DIGIT_NUMBER = 99;
export const MAX_TRIPLE_DIGIT_NUMBER = 999;
export const FEATURED_MARKET_MAX_RUNNERS_LENGTH_TO_DISPLAY = 4;
export const CHANGE_BANNER_INTERVAL = 3000;
export const MAX_BANNERS_ITEMS = 5;

export const BannerNames = {
  homePage: 'homePage',
  subPageContent: 'subPageContent',
  badge: 'badge',
  badgeSkyscraper: 'badgeSkyscraper',
  promotion: 'promotion',
  logo: 'logo',
  footerLogo: 'footerLogo',
  favicon: 'favicon',
  notFoundBackground: 'notFoundBackground'
} as const;

export type BannerNamesKeys = typeof BannerNames[keyof typeof BannerNames];

export const GeneralWebSocketSubscriptionTypes = {
  currentBets: 'CURRENT_BETS',
  cashOutQuote: 'CASH_OUT_QUOTE',
  autoCashOut: 'AUTO_CASH_OUT',
  eventUpdates: 'EVENT_UPDATES',
  eventUpdatesPL: 'EVENT_INFO_UPDATES_PL', // subscription type for profit loss table pop up
  eventInfoUpdates: 'EVENT_INFO_UPDATES', // shortened version of event-updates data -> without updateDetails field
  balance: 'BALANCE',
  cashOutCounter: 'CASH_OUT_COUNTER',
  cashOutQuoteAsianBetSlip: 'CASH_OUT_QUOTE_ASIAN_BET_SLIP',
  cashOutQuoteAsianEvent: 'CASH_OUT_QUOTE_ASIAN_EVENT',
  autoCashOutAsianBetSlip: 'AUTO_CASH_OUT_ASIAN_BET_SLIP',
  autoCashOutAsianEvent: 'AUTO_CASH_OUT_ASIAN_EVENT',
  properties: 'PROPERTIES'
} as const;

export type GeneralWebSocketSubscriptionTypesKeys =
  typeof GeneralWebSocketSubscriptionTypes[keyof typeof GeneralWebSocketSubscriptionTypes];

export const PROFIT_LOSS_TABLE_POP_UP_FETCH_DATA_INTERVAL = 15000;
export const PROFIT_LOSS_TABLE_POP_UP_COUNT_DOWN_INTERVAL = 1000;
export const PROFIT_LOSS_TABLE_POP_UP_REFRESH_DATA_INIT_SECONDS = 15;

export const AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL = 3000;
export const PROGRESS_BAR_INTERVAL = 1000;

export const BODY_ID = 'biab_body';

// FYI: the need to create this constant arises because at some point pages (in particular during refresh) are not loaded yet and the url is an empty string
export const RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL = '/pages/en/rg_tools_self_exclusion_start_page_link';

// FIY: Maximum value for setTimeout (about 24.85 days)
export const SAFE_TIME_OUT_PERIOD_IN_MINUTES = 35784;
export const LOCATION_BEFORE_LOGIN_SEARCH_PARAM_NAME = 'locationBeforeLogin';

export const Devices = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP'
} as const;

export const Platforms = {
  WEB: 'WEB'
} as const;

export const CommissionTypes = {
  WINNINGS: 'WINNINGS',
  PRICES: 'PRICES'
} as const;

export const Methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
} as const;

export const CookieNames = {
  IS_LOGGING_IN: 'BIAB_IS_LOGGING_IN',
  LOGIN_POP_UP_SHOWN: 'BIAB_LOGIN_POP_UP_SHOWN',
  DOMAIN: 'domain',
  I18_NEXT: 'i18next',
  API_URL: 'BIAB_API_URL',
  CSRF: 'CSRF-TOKEN',
  TOKEN: 'BIAB_TOKEN',
  REFERER: 'REFERER',
  BIAB_NEW_CUSTOMER: 'BIAB_NEW_CUSTOMER',
  TIME_FORMAT: 'BIAB_TIME_FORMAT',
  ODDS_TYPE: 'BIAB_ODDS_TYPE',
  LANGUAGE: 'BIAB_LANGUAGE',
  TIMEZONE: 'BIAB_TZ',
  LICENSE: 'BIAB_LICENSE',
  CONFIRM_BETS: 'BIAB_CONFIRM_BETS',
  BETSLIP_FILTER_MARKET: 'FILTER_BETS_BY_MARKET_ID',
  BETSLIP_FILTER_SORTING: 'SORTED_BETS',
  BETSLIP_FILTER_EVENT: 'FILTER_BETS_BY_EVENT_ID',
  IN_PLAY_VIEW_BY: 'BIAB_INPLAY_VIEW_BY',
  IN_PLAY_COLLAPSED_SPORTS: 'BIAB_INPLAY_COLlAPSED_SPORTS',
  MARKET_ODDS_COLLAPSED: 'BIAB_MARKET_ODDS_COLLAPSED',
  MARKETS_COLLAPSE: 'MARKETS_COLLAPSED',
  MARKETS_CLOSED: 'MARKETS_CLOSED',
  SHOW_TOOLTIPS: 'BIAB_SHOW_TOOLTIPS',
  RACING_PERIOD: 'BIAB_HORSE_RACING_PERIOD',
  BIAB_MARKET_NET_COMMISSION: 'BIAB_MARKET_NET_COMMISSION',
  COMPETITION_TIME_FILTER: 'BIAB_COMPETITION_TIME_FILTER',
  COMPETITION_VIEW_BY: 'BIAB_COMPETITION_VIEW_BY',
  COMPETITION_COLLAPSED: 'BIAB_COMPETITION_COLLAPSED',
  MY_BETS_TYPES: 'BIAB_MY_BETS_TYPES',
  ACCOUNT_DATEFROM: 'BIAB_ACCOUNT_DATEFROM',
  ACCOUNT_DATETO: 'BIAB_ACCOUNT_DATETO',
  ACCOUNT_STATEMENT_DATEFROM: 'ACCOUNT_STATEMENT_DATEFROM',
  ACCOUNT_STATEMENT_DATETO: 'ACCOUNT_STATEMENT_DATETO',
  PROFITANDLOSS_TIMERANGE: 'PROFITANDLOSS_TIMERANGE',
  PROFITANDLOSS_BETTYPE: 'PROFITANDLOSS_BETTYPE',
  PROFITANDLOSS_SPORTID: 'PROFITANDLOSS_SPORTID',
  PL_NET_COMMISSION: 'PL_NET_COMMISSION',
  FANCY_SECTIONS_COLLAPSED: 'BIAB_FANCY_SECTIONS_COLLAPSED',
  MOBILE_OPEN_BETS: 'BIAB_MOBILE_OPEN_BETS',
  TOP_MARKETS_IS_OPENED: 'BIAB_TOP_MARKETS_IS_OPENED',
  TOP_MARKETS_COLLAPSED: 'BIAB_TOP_MARKETS_COLLAPSED',
  MULTI_MARKETS_OTHER_IS_OPENED: 'BIAB_MULTI_MARKETS_OTHER_IS_OPENED',
  MULTI_MARKETS_COLLAPSED: 'MULTI_MARKETS_COLLAPSED',
  BIAB_MULTIPLE_BANNERS_SHOW_ALL: 'BIAB_MULTIPLE_BANNERS_SHOW_ALL',
  PL_TABLE_VISIBLE: 'PL_TABLE_VISIBLE',
  BIAB_NEW_USER: 'BIAB_NEW_USER',
  BIAB_MOBILE_FAV_TAB_IS_ACTIVE: 'BIAB_MOBILE_FAV_TAB_IS_ACTIVE',
  BIAB_LOGIN_POP_UP_SHOWN: 'BIAB_LOGIN_POP_UP_SHOWN',
  BIAB_BETSLIP_NET_PROFIT_LOSS: 'BIAB_BETSLIP_NET_PROFIT_LOSS',
  ASIAN_VIEW_SORTING_FILTER: 'ASIAN_VIEW_SORTING_FILTER',
  ASIAN_VIEW_LINE_VIEW_FILTER: 'ASIAN_VIEW_LINE_VIEW_FILTER',
  ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED: 'ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED',
  ASIAN_VIEW_LAY_BETTING: 'ASIAN_VIEW_LAY_BETTING',
  ASIAN_VIEW_QUICK_BETTING: 'ASIAN_VIEW_QUICK_BETTING',
  EXCHANGE_TYPE_NEW: 'EXCHANGE_TYPE_NEW',
  ASIAN_VIEW_BET_LIST_SORTING: 'ASIAN_VIEW_BET_LIST_SORTING',
  COLLAPSE_SIDEBAR: 'COLLAPSE_SIDEBAR',
  MY_BETS_SORT_BY: 'BIAB_MY_BETS_SORT_BY',
  MY_BETS_SORT_ORDER: 'BIAB_MY_BETS_SORT_ORDER',
  MY_BETS_SEARCH: 'BIAB_MY_BETS_SEARCH',
  BETSLIP_SORTED_EVENTS_COLLAPSED: 'BETSLIP_SORTED_EVENTS_COLLAPSED'
} as const;

export const SortingCookieValues = {
  BY_PLACEMENT: 'BY_PLACEMENT',
  BY_EVENT: 'BY_EVENT'
} as const;

export const OddsTypes = {
  DEC: 'decimal',
  MY: 'malay',
  HK: 'hongKong',
  IND: 'indonesian'
} as const;

export const PlacementPages = {
  TOP_5_VIEW: 'top_5_view',
  FEATURED_SPORTS: 'featured-sports',
  FEATURED_SPORTS_ODDS_BANNER: 'featured-sports/odds_banner',
  FEATURED_MARKETS: 'featured-markets',
  FEATURED_MARKETS_ODDS_BANNER: 'featured-markets/odds_banner',
  MARKET: 'market',
  MARKET_ODDS_BANNER: 'market/odds_banner',
  SPORT: 'sport',
  SPORT_ODDS_BANNER: 'sport/odds_banner',
  COMPETITION: 'competition',
  COUNTRY: 'country',
  COUNTRY_ODDS_BANNER: 'country/odds_banner',
  COMPETITION_ODDS_BANNER: 'competition/odds_banner',
  MULTI_MARKET: 'multi-market',
  MULTI_MARKET_ODDS_BANNER: 'multi-market/odds_banner',
  GROUP: 'group',
  GROUP_ODDS_BANNER: 'group/odds_banner',
  IN_PLAY: 'inplay',
  IN_PLAY_ODDS_BANNER: 'inplay/odds_banner',
  CASH_OUT: 'cashout',
  EVENT: 'event',
  EVENT_ODDS_BANNER: 'event/odds_banner',
  LIST_VIEW: 'list-view',
  FANCY_PAGE: 'fancy-page',
  FANCY_PAGE_ODDS_BANNER: 'fancy-page/odds_banner',
  GAME: 'game',
  ASIAN: 'asian'
} as const;

export const BasketballPlayStatuses = {
  FINISH: 'Finished',
  FINISH_HALF_END: 'FirstHalfEnd',
  STOP_QUARTER: 'StopQuarter'
} as const;

export const CashOutTabs = {
  auto: 0,
  partial: 1
} as const;

export const GeneralCashOutStatuses = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  VOID: 'VOID'
} as const;

export const PopularEventTypes = {
  EVENT_TYPE: 'EVENT_TYPE',
  COMPETITION: 'COMPETITION',
  CUSTOM_POP_UP: 'CUSTOM_POP_UP',
  RACE: 'RACE',
  EVENT: 'EVENT',
  GROUP: 'GROUP'
} as const;

export const CurrencySettingsPlacements = {
  TOOLTIP: 'TOOLTIP',
  MATCHED_AMOUNT: 'MATCHED_AMOUNT'
} as const;

export const EventStatuses = {
  InPlay: 'IN_PLAY',
  CLOSED: 'CLOSED',
  COMPLETE: 'COMPLETE',
  FIXTURE: 'FIXTURE'
} as const;

export const EventViewTypes = {
  DEFAULT: 'DEFAULT',
  MULTI_MARKET_VIEW: 'MULTI_MARKET_VIEW',
  TOP_5_VIEW: 'TOP_5_VIEW'
} as const;

export const HomePageTypes = {
  FEATURED_SPORTS: 'FEATURED_SPORTS',
  FEATURED_MARKETS: 'FEATURED_MARKETS',
  LINK: 'LINK'
} as const;

export const ViewsBy = {
  TIME: 'TIME',
  POPULARITY: 'POPULARITY',
  COMPETITION: 'COMPETITION'
} as const;

export const LiveWidgetHeaderTypes = {
  MARKET: 'market',
  EVENT: 'event'
} as const;

export const PrecisionTypes = {
  HUNDREDTHS: 'HUNDREDTHS',
  TENTHS: 'TENTHS',
  UNITS: 'UNITS'
} as const;

export const MarketNameDividers = {
  VERSUS: ' vs ',
  AT: ' @ ',
  SHORT_VERSUS: ' v '
} as const;

export const PageBlocks = {
  HOME: 'HOME',
  MARKET_ODDS: 'MARKET_ODDS',
  FANCY_VIEW: 'FANCY_VIEW',
  FANCY_TOP_VIEW: 'FANCY_TOP_VIEW',
  IN_PLAY: 'IN_PLAY',
  POPULAR: 'POPULAR',
  FEATURE_MARKET: 'FEATURE_MARKET',
  EVENT: 'EVENT',
  SPORT: 'SPORT',
  SEARCH: 'SEARCH',
  TOP_5_VIEW: 'TOP_5_VIEW',
  TOP_5_VIEW_TOP_MARKET: 'TOP_5_VIEW_TOP_MARKET',
  MULTI_MARKET_VIEW: 'MULTI_MARKET_VIEW',
  MULTI_MARKET_VIEW_TOP_MARKET: 'MULTI_MARKET_VIEW_TOP_MARKET',
  FANCY_MULTI_MARKET_VIEW: 'FANCY_MULTI_MARKET_VIEW',
  GAME: 'GAME',
  CASH_OUT: 'CASH_OUT'
} as const;

export const MatchStatuses = {
  HALF_TIME: 'FirstHalfEnd',
  END: 'Finished',
  FIRST_HALF: '1H',
  SECOND_HALF: '2H',
  KICK_OFF: 'KickOff',
  SECOND_HALF_KICK_OFF: 'SecondHalfKickOff',
  PENALTY_KICK_OFF: 'PenaltyKickOff'
} as const;

export const ApplicationTypes = {
  WEB: 'WEB'
} as const;

export const MarketStatuses = {
  CLOSED: 'CLOSED',
  HIDDEN: 'HIDDEN',
  SUSPENDED: 'SUSPENDED',
  REMOVED: 'REMOVED',
  OPEN: 'OPEN',
  IN_PLAY: 'IN_PLAY'
} as const;

export const MetaPages = {
  HOME: 'HOME',
  EVENT: 'EVENT',
  CUSTOM: 'CUSTOM',
  IN_PLAY: 'IN_PLAY',
  SPORT: 'SPORT',
  COMPETITION: 'COMPETITION',
  COUNTRY: 'COUNTRY',
  MARKET: 'MARKET'
} as const;

export const ElementNames = {
  HEADER_HEIGHT: 'HEADER_HEIGHT',
  FOOTER_HEIGHT: 'FOOTER_HEIGHT',
  COLLAPSE_HEIGHT: 'COLLAPSE_HEIGHT',
  HOW_TO_MENU_TITLE_HEIGHT: 'HOW_TO_MENU_TITLE_HEIGHT',
  HOW_TO_SECTION_HEIGHT: 'HOW_TO_SECTION_HEIGHT',
  ACTIVE_MENU_TITLE_HEIGHT: 'ACTIVE_MENU_TITLE_HEIGHT',
  ACTIVE_MENU_HEIGHT: 'ACTIVE_MENU_HEIGHT',
  FAVORITES_MENU_TITLE_HEIGHT: 'FAVORITES_MENU_TITLE_HEIGHT',
  FAVORITES_MENU_HEIGHT: 'FAVORITES_MENU_HEIGHT',
  NAVIGATION_PARENT_SECTION: 'NAVIGATION_PARENT_SECTION',
  NAVIGATION_PARENT_SPORT_NAME: 'NAVIGATION_PARENT_SPORT_NAME',
  NAVIGATION_ALL_SPORT_ITEM: 'NAVIGATION_ALL_SPORT_ITEM',
  NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT: 'NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT',
  NAVIGATION_HIDE_NAVBAR_BUTTON: 'NAVIGATION_HIDE_NAVBAR_BUTTON',
  CONTEXTUAL_HELP: 'CONTEXTUAL_HELP',
  BANNER: 'BANNER',
  FAVORITES_CONTENT_HEIGHT: 'FAVORITES_CONTENT_HEIGHT',
  ACTIVE_MARKETS_CONTENT_HEIGHT: 'ACTIVE_MARKETS_CONTENT_HEIGHT',
  WEBTOURS_IMAGE: 'WEBTOURS_IMAGE',
  SIDEBAR_HEIGHT: 'SIDEBAR_HEIGHT',
  CONTENT_HEIGHT: 'CONTENT_HEIGHT',
  NAVIGATION_HEIGHT: 'NAVIGATION_HEIGHT',
  SEARCH_INPUT_HEIGHT: 'SEARCH_INPUT_HEIGHT',
  CASHOUT_HEADER_HEIGHT: 'CASHOUT_HEADER_HEIGHT',
  MIN_NAV_HEIGHT: 'MIN_NAV_HEIGHT',
  MOBILE_FOOTER_NAV_HEIGHT: 'MOBILE_FOOTER_NAV_HEIGHT',
  MOBILE_PAGE_HEIGHT: 'MOBILE_PAGE_HEIGHT',
  MOBILE_CONTENT_HEIGHT: 'MOBILE_CONTENT_HEIGHT',
  MOBILE_HEADER_HEIGHT: 'MOBILE_HEADER_HEIGHT',
  MOBILE_SWIPE_MENU_HEIGHT: 'MOBILE_SWIPE_MENU_HEIGHT',
  MOBILE_ASIAN_SUB_HEADER: 'MOBILE_ASIAN_SUB_HEADER',
  POPULAR_MARKETS_HEIGHT: 'POPULAR_MARKETS_HEIGHT',
  ASIAN_NAVIGATION_SPORTS_HEIGHT: 'ASIAN_NAVIGATION_SPORTS_HEIGHT',
  ASIAN_FAVOURITES_NAVIGATION: 'ASIAN_FAVOURITES_NAVIGATION',
  ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY: 'ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY',
  ASIAN_BET_SLIP_TABS: 'ASIAN_BET_SLIP_TABS',
  ASIAN_TIMEZONE: 'ASIAN_TIMEZONE',
  ASIAN_BALANCE: 'ASIAN_BALANCE',
  ASIAN_BET_SLIP_COLLAPSE: 'ASIAN_BET_SLIP_COLLAPSE',
  ASIAN_MIDDLE_SECTION: 'ASIAN_MIDDLE_SECTION'
} as const;

export const BetsStatusesTypes = {
  PLACED: 'PLACED',
  MATCHED: 'MATCHED',
  PENDING: 'PENDING',
  LAPSED: 'LAPSED',
  VOIDED: 'VOIDED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  UNMATCHED: 'UNMATCHED',
  PARTIALLY_MATCHED: 'PARTIALLY_MATCHED'
} as const;

export const CurrentBetActions = {
  HIDDEN: 'HIDDEN',
  CANCELLING: 'CANCELLING',
  CANCELLING_ALL: 'CANCELLING_ALL',
  EDITING: 'EDITING',
  FULLY_MATCHED: 'FULLY_MATCHED',
  PARTIALLY_MATCHED: 'PARTIALLY_MATCHED',
  FULLY_MATCHED_AFTER_EDITING: 'FULLY_MATCHED_AFTER_EDITING',
  PLACED_PARTIALLY_MATCHED: 'PLACED_PARTIALLY_MATCHED'
} as const;

export const AutoCashOutStatuses = {
  NEW: 'NEW',
  NEW_CREATED: 'NEW_CREATED',
  ACTIVE: 'ACTIVE',
  EXISTS_REMOVED: 'EXISTS_REMOVED',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS'
} as const;

export const CashOutStatuses = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  SUCCESS_WITH_BETTER_VALUE: 'SUCCESS_WITH_BETTER_VALUE',
  UNSUCCESSFUL_BAD_RE_QUOTE: 'UNSUCCESSFUL_BAD_RE_QUOTE',
  UNSUCCESSFUL_EXPOSURE_LIMITS: 'UNSUCCESSFUL_EXPOSURE_LIMITS',
  UNSUCCESSFUL_PLACEMENT_FAILURE: 'UNSUCCESSFUL_PLACEMENT_FAILURE'
} as const;

export const CancelActionStatuses = {
  SUCCESSFUL: 'SUCCESSFUL',
  SUCCESSFUL_WITH_LESS_CANCELLED: 'SUCCESSFUL_WITH_LESS_CANCELLED',
  FAILED: 'FAILED',
  CREATED: 'CREATED'
} as const;

export const TypeFields = {
  home: 'home',
  sport: 'sport',
  game: 'game',
  search: 'search',
  myBets: 'myBets',
  cashOut: 'cashOut',
  favourites: 'favourites',
  more: 'more',
  betList: 'betList'
} as const;

export const IFrameMessageTypes = {
  AUTHENTICATION: 'authentication',
  LOGGED_IN: 'loggedIn',
  CHANGE_LOCALE: 'changeLocale',
  CHANGE_TIMEZONE: 'changeTimezone',
  LOGGED_OUT: 'loggedOut',
  LOGIN_TO_PLACE_BET: 'loginToPlaceBet',
  CHECK_ORIGIN: 'checkOrigin',
  USER_BALANCE: 'userBalance'
} as const;

export const WithCollapsePositions = {
  LEFT: 'left',
  RIGHT: 'right'
} as const;

import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MobileAccountBackBtn from 'components/MobileAccountBackBtn';
import MobileLocaleTimezoneView from 'components/MobileLocaleTimezoneView/MobileLocaleTimezoneView';
import MobilePreferences from 'components/MobilePreferences/MobilePreferences';
import { ElementNames } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import useMobilePreferences from 'hooks/useMobilePreferences';
import {
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled,
  getMobileSettingsDefStake,
  getMobileSettingsDepositBtn,
  getMobileSettingsQuickstakeBetslip
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getAppSettings } from 'redux/modules/appSettings/selectors';
import { MobilePreferencesOption } from 'types/mobile';
import { getDefaultLinkTarget } from 'utils/navigation';

import AccountLinksSection from './components/AccountLinksSection/AccountLinksSection';
import BetSlipSettingsSection from './components/BetSlipSettingsSection/BetSlipSettingsSection';
import DefaultStakeSection from './components/DefaultStakeSection/DefaultStakeSection';
import QuickBetsSection from './components/QuickBetsSection/QuickBetsSection';
import QuickBetsView from './components/QuickBetsView/QuickBetsView';

import styles from './styles.module.scss';

const MobileAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const depositBtn = useSelector(getMobileSettingsDepositBtn);
  const settings = useSelector(getAppSettings);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const defStake = useSelector(getMobileSettingsDefStake);
  const quickstakeBetslip = useSelector(getMobileSettingsQuickstakeBetslip);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const [isQuickBetsView, setIsQuickBetsView] = useState(false);
  const [headerResizeElement, setHeaderResizeElement] = useState<HTMLElement | null>(null);

  const isShowDepositLink = depositBtn && settings.length;
  const isQuickStakesEnabled = quickstakeBetslip && (exchangeSportsEnabled || exchangeGamesEnabled);
  const [wrapperRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: height }));
    }
  });

  const depositLinkTarget = getDefaultLinkTarget({
    url: settings[0]?.url,
    absolutePath: true,
    isIframeEnabled,
    currentTarget: '_blank',
    openInNewTab: true
  });

  const {
    setShowTimezoneView,
    setShowLocaleView,
    selectedLocale,
    selectedOffset,
    showLocaleView,
    showTimezoneView,
    onSelectLocal,
    onSelectTimezone,
    timezoneOffsets,
    locales
  } = useMobilePreferences();

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    setHeaderResizeElement(document.getElementById('header-resize-container'));
  }, []);

  if (isQuickBetsView) {
    return <QuickBetsView onGoBack={() => setIsQuickBetsView(false)} />;
  }

  if ((showLocaleView || showTimezoneView) && selectedLocale && selectedOffset) {
    return (
      <MobileLocaleTimezoneView
        onGoBack={() => (showTimezoneView ? setShowTimezoneView(false) : setShowLocaleView(false))}
        onChangeOption={showLocaleView ? onSelectLocal : onSelectTimezone}
        label={showLocaleView ? t('locale.label') : t('timezone.label')}
        options={showLocaleView ? locales : (timezoneOffsets as MobilePreferencesOption[])}
        selectedOption={showLocaleView ? selectedLocale : (selectedOffset as MobilePreferencesOption)}
        type={showLocaleView ? 'locale' : 'timezone'}
      />
    );
  }

  const header = (
    <div className={styles.account__header}>
      <MobileAccountBackBtn
        className={styles.account__header__home}
        hideLabel
        iconName="biab_custom-icon-home"
        iconClassName={styles.account__header__home__icon}
      />
      <h2 className={styles.account__header__title}>{t('header.myAccount')}</h2>
      {isShowDepositLink ? (
        <a href={settings[0]?.url} target={depositLinkTarget} className={styles.account__header__depositLink}>
          {t('accountNavigation.deposit')}
        </a>
      ) : (
        <div className={styles.account__header__empty} />
      )}
    </div>
  );

  return (
    <div ref={wrapperRef}>
      {/* TODO createPortal is used because position sticky is not working for this header, need to find better solution in future */}
      {headerResizeElement ? createPortal(header, headerResizeElement) : header}
      <div className={styles.account__container}>
        <div className={styles.account__container__topBg} />
        <div className={styles.account__content}>
          <AccountLinksSection />
          <BetSlipSettingsSection />
          {defStake && <DefaultStakeSection />}
          {isQuickStakesEnabled && <QuickBetsSection showQuickBetsView={() => setIsQuickBetsView(true)} />}
          <MobilePreferences
            setShowLocaleView={setShowLocaleView}
            setShowTimezoneView={setShowTimezoneView}
            selectedOffset={selectedOffset as MobilePreferencesOption}
            selectedLocale={selectedLocale}
            isAccountPage
          />
        </div>
      </div>
    </div>
  );
};

export default MobileAccount;

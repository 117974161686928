import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import LoadingSkeleton from 'components/AsianViewPageModule/components/LoadingSkeleton';
import Pagination from 'components/Pagination';
import { ASIAN_TABS_EARLY_PREFIX } from 'constants/app';
import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_VIEW_LIST_SIZE,
  AsianLineViewTypes,
  AsianSortingTypes,
  AsianViewMarketLinks,
  AsianViewSections,
  AsianViewSortings,
  AsianViewTimeFilters,
  AsianViewTimeFrames
} from 'constants/asianView';
import useAsianSingleView from 'hooks/useAsianSingleView';
import useElementSize from 'hooks/useElementSize';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useLeaguesParam from 'hooks/useLeaguesParam';
import usePagination from 'hooks/usePagination';
import {
  getAsianViewMiddleSectionPageSize,
  getBackendPages,
  getIsDesktopInfiniteScrollEnabled,
  getIsMobileInfiniteScrollEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { setScrollUpdate } from 'redux/modules/appSettings';
import { fetchAsianViewList, setCollapses, setIsAsianViewNotFoundView } from 'redux/modules/asianView';
import {
  getAreEventCouponsLoaded,
  getAsianViewDaysValue,
  getAsianViewEvents,
  getAsianViewLast,
  getAsianViewLoading,
  getAsianViewTotalElements,
  getAsianViewTotalPages,
  getIsAsianViewNotFoundView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';
import { getLeaguesCurParams, getLeaguesIsLoaded } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchPageData } from 'redux/modules/pages';
import { getBackendPageContentLoading } from 'redux/modules/pages/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { AsianViewMarketLink, AsianViewTimeFilter, AsianViewTimeFrame } from 'types/asianView';

import OutRightMarket from '../OutRightMarket';

import styles from './styles.module.scss';

const MarketLinkOutRight = () => {
  const dispatch = useDispatch();
  const { timeFilter = '', sportId = '', marketLink = '', timeFrame } = useParams();
  const [searchParams] = useSearchParams();

  const events = useSelector(getAsianViewEvents);
  const daysValue = useSelector(getAsianViewDaysValue);
  const totalPages = useSelector(getAsianViewTotalPages);
  const totalElements = useSelector(getAsianViewTotalElements);
  const isDesktopInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);
  const isMobileInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);
  const itemsCountOnPage = useSelector(getAsianViewMiddleSectionPageSize);
  const loading = useSelector(getAsianViewLoading);
  const isLast = useSelector(getAsianViewLast);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isLeaguesLoaded = useSelector(getLeaguesIsLoaded);
  const curLeaguesParam = useSelector(getLeaguesCurParams);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const areEventCouponsLoaded = useSelector(getAreEventCouponsLoaded);
  const isAsianViewNotFoundView = useSelector(getIsAsianViewNotFoundView);
  const isLoadingBackendPage = useSelector(getBackendPageContentLoading);
  const pages = useSelector(getBackendPages);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const [page, setPage] = useState(0);

  const infiniteScrollPageRef = useRef(0);

  const [componentRef, { width }] = useElementSize<HTMLDivElement>();
  const leaguesParam = useLeaguesParam();
  const { isAsianSingleView } = useAsianSingleView();

  const isInfiniteScrollEnabled = isMobileAsianView ? isMobileInfiniteScrollEnabled : isDesktopInfiniteScrollEnabled;
  const isEarlyTab = timeFilter === ASIAN_TABS_EARLY_PREFIX;
  const timeFrameParam = isEarlyTab
    ? { timeFrame: isMobileAsianView ? (timeFrame as AsianViewTimeFrame) ?? AsianViewTimeFrames.ALL : daysValue }
    : {};
  const marketId = searchParams.get(ASIAN_OUTRIGHT_MARKET_ID);
  const competitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightMarketView = !!competitionId;
  const timeFilterParam = timeFilter as AsianViewTimeFilter;
  const show404Page =
    !loading && areEventCouponsLoaded && events.length === 0 && isPropertiesLoaded && isSingleOutrightMarketView;
  const marketLinkParam = isAsianSingleView ? AsianViewMarketLinks.HDP_AND_OU : (marketLink as AsianViewMarketLink);

  const handleFetchAsianViewListPagination = (nextPage: number) => {
    dispatch(
      fetchAsianViewList({
        id: sportId,
        page: nextPage,
        size: itemsCountOnPage,
        payload: {
          marketLink: AsianViewMarketLinks.OUTRIGHT,
          timeFilter: timeFilterParam,
          competitions: leaguesParam,
          sections: [AsianViewSections.TOP],
          sorting: AsianViewSortings[AsianSortingTypes.NORMAL],
          ...timeFrameParam
        },
        isInfiniteScrollEnabled: false,
        resetPrev: true
      })
    );

    dispatch(setScrollUpdate({ top: 0, trigger: true, offset: 0 }));
  };

  const {
    page: paginationPage,
    onClickLastPage,
    onClickNextPage,
    onClickPrevPage,
    onClickFirstPage,
    onClickPage,
    changePage
  } = usePagination({
    onChangePage: handleFetchAsianViewListPagination,
    totalPages,
    isPaginationEnabled: !isInfiniteScrollEnabled
  });

  useEffect(() => {
    if (isPropertiesLoaded && isLeaguesLoaded && curLeaguesParam === `${sportId}-${marketLinkParam}-${timeFilter}`) {
      if (isInfiniteScrollEnabled && page !== 0) {
        setPage(0);
        infiniteScrollPageRef.current = 0;
      }
      const offsetOrPage = isInfiniteScrollEnabled ? { offset: 0 } : { page: paginationPage };
      const marketsParam = isSingleOutrightMarketView && !!marketId ? { markets: [marketId] } : {};

      dispatch(
        fetchAsianViewList({
          ...offsetOrPage,
          id: sportId,
          size: isInfiniteScrollEnabled ? ASIAN_VIEW_LIST_SIZE : itemsCountOnPage,
          payload: {
            marketLink: AsianViewMarketLinks.OUTRIGHT,
            timeFilter: isSingleOutrightMarketView ? AsianViewTimeFilters.ALL : timeFilterParam,
            competitions: isSingleOutrightMarketView ? [competitionId] : leaguesParam,
            sections: [AsianViewSections.TOP],
            sorting: AsianViewSortings[AsianSortingTypes.NORMAL],
            ...timeFrameParam,
            ...marketsParam,
            viewType: AsianLineViewTypes.DOUBLE
          },
          isInfiniteScrollEnabled,
          changePage,
          resetPrev: true
        })
      );
    }
  }, [
    dispatch,
    timeFilterParam,
    leaguesParam,
    sportId,
    isPropertiesLoaded,
    itemsCountOnPage,
    timeFrameParam?.timeFrame,
    isLeaguesLoaded,
    competitionId,
    marketId,
    isSingleOutrightMarketView,
    isLoggedIn
  ]);

  useEffect(() => {
    if (page !== 0 && infiniteScrollPageRef.current !== 0 && !isLast && isInfiniteScrollEnabled) {
      dispatch(
        fetchAsianViewList({
          id: sportId,
          offset: page * ASIAN_VIEW_LIST_SIZE,
          size: ASIAN_VIEW_LIST_SIZE,
          payload: {
            marketLink: AsianViewMarketLinks.OUTRIGHT,
            timeFilter: timeFilterParam,
            competitions: leaguesParam,
            sections: [AsianViewSections.TOP],
            sorting: AsianViewSortings[AsianSortingTypes.NORMAL],
            ...timeFrameParam
          },
          isInfiniteScrollEnabled: true
        })
      );
    }
  }, [dispatch, page, isLast]);

  const infiniteScrollCallback = useCallback(() => {
    if (!loading && !isLast && isInfiniteScrollEnabled) {
      setPage(prevPage => {
        infiniteScrollPageRef.current = prevPage + 1;

        return prevPage + 1;
      });
    }
  }, [loading, isLast, isInfiniteScrollEnabled]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  useEffect(() => {
    const collapse: string[] = [];
    if (Array.isArray(events)) {
      events.forEach(event => {
        const sections = Object.values(event.sections)[0];
        sections.forEach(section => collapse.push(section.id));
      });
      dispatch(setCollapses({ collapse }));
    }
  }, [dispatch, events]);

  useEffect(() => {
    if (show404Page) {
      dispatch(fetchPageData(pages[PagesFromBackend.PAGE_NOT_FOUND] as string));
      dispatch(setIsAsianViewNotFoundView(true));
    } else if (isAsianViewNotFoundView) {
      dispatch(setIsAsianViewNotFoundView(false));
    }
  }, [show404Page]);

  useEffect(() => {
    return () => {
      if (isAsianViewNotFoundView) {
        dispatch(setIsAsianViewNotFoundView(false));
      }
    };
  }, []);

  return (
    <div
      ref={componentRef}
      className={classNames({
        [styles.container]: loading,
        hidden: isAsianViewNotFoundView
      })}
    >
      {!isAsianViewNotFoundView && (
        <>
          {events.map(event => {
            const sections = [...Object.values(event.sections)[0]];
            sections.sort((a, b) => a.name.localeCompare(b.name));

            return (
              <Fragment key={event.id}>
                {sections.map(section => (
                  <OutRightMarket
                    key={`${event.id}-${section.id}`}
                    width={width}
                    competitionId={event.competitionId}
                    eventTranslations={event.translations}
                    eventId={event.id}
                    startTime={section.startTime}
                    sectionId={section.id}
                    sectionRunners={section.runners}
                    sectionTranslations={section.translations}
                    cashOutEnabled={section.cashOutEnabled}
                  />
                ))}
              </Fragment>
            );
          })}
          {loading || (show404Page && isLoadingBackendPage) ? (
            <LoadingSkeleton />
          ) : (
            isInfiniteScrollEnabled && <div ref={lastElementRef} />
          )}
          {!isInfiniteScrollEnabled && totalElements > itemsCountOnPage && !loading && totalElements > 0 && (
            <Pagination
              page={paginationPage}
              totalPages={totalPages}
              onClickFirstPage={onClickFirstPage}
              onClickLastPage={onClickLastPage}
              onClickNextPage={onClickNextPage}
              onClickPage={onClickPage}
              onClickPrevPage={onClickPrevPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MarketLinkOutRight;

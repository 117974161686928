import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toNumber } from 'lodash';

import MyBetsStandartCell from 'components/MyBetsStandardCell';
import { BettingTypes } from 'constants/markets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { getMyBetsMultiCurrencyValue } from 'redux/modules/myBets/selectors';
import { TPLContentItem } from 'redux/modules/myBets/type';
import { parseMillisecondsToTime } from 'utils/date';

import styles from './styles.module.scss';

interface ContentItemProps {
  item: TPLContentItem;
}

const ContentItem = ({ item }: ContentItemProps) => {
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { date: parsedSettledDate } = useParseMyBetsDates({
    dateToParse: item.settledDate,
    yearHyphen: false,
    divider: ','
  });
  const { date: startDate } = useParseMyBetsDates({
    dateToParse: item.startTime,
    yearHyphen: false,
    divider: ','
  });
  const { noFormattedAmount: plProfit } = useFormatCurrency(
    item.profit,
    isMultiCurrencySupported && multiCurrencyValue ? multiCurrencyValue : '',
    {
      noRounding: true,
      isCheckIndian: true,
      hideCurrencySymbol: !isMultiCurrencySupported,
      isPositiveSign: true,
      noZeroSign: true
    }
  );

  const startTime = parseMillisecondsToTime(item.startTime || 0, timezone, timezoneCookieEnabled);
  const raceInfo = item.raceName ? startTime + ' ' + item.raceName : '';
  const marketName = `${raceInfo ? raceInfo + ' / ' : ''}${
    item.betType === BettingTypes.GAME ? `${t('games.gameId')}: ${item.marketId}` : item.marketName
  }`;

  return (
    <div key={item.settledDate} className={classNames('biab_my-bets-table-body', styles.tableBody)}>
      <MyBetsStandartCell classname={classNames(styles.cell, styles.eventCell)} value={item.eventName} />
      <MyBetsStandartCell classname={classNames(styles.cell, styles.marketCell)} value={marketName} />
      <MyBetsStandartCell classname={classNames(styles.cell, styles.middleCell)} value={startDate} />
      <MyBetsStandartCell classname={classNames(styles.cell, styles.middleCell)} value={parsedSettledDate} />
      <MyBetsStandartCell
        classname={classNames(styles.cell, styles.plCell, {
          [styles.betWon]: toNumber(item.profit) > 0,
          [styles.betLost]: toNumber(item.profit) < 0
        })}
        value={plProfit}
      />
    </div>
  );
};
export default ContentItem;

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { TFunction } from 'i18next';

import { BETTING_TYPES, CookieNames, GAME, SportIds } from 'constants/app';
import { accountPagesBranding } from 'constants/branding';
import {
  BetSides,
  ExchangeTypes,
  lineAvgPriceFlatValue,
  SEPARATE_WALLET,
  SINGLE_WALLET,
  StatementDataStatuses
} from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import {
  getIsAmericanDateFormatEnabled,
  getMobileSettingsAlternativeLayOdds,
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver,
  getMobileSwapColorsFancyMarketsOnCricket,
  getTimezone,
  getTimezoneCookieEnabled,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { getAccountProductsByBetsType, getMobileStatementItemDetails } from 'redux/modules/myBets/selectors';
import { BetsType, TStatementContentItem } from 'redux/modules/myBets/type';
import { applyTimezone, parseMillisecondsToTime } from 'utils/date';
import {
  betsSideValue,
  getAccountStatementIndicators,
  getAccountStatementStatusLabel,
  getOriginLabel,
  getSelectionName
} from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import styles from './AccountStatementItemMobileDetails.module.scss';

const getFormattedDate = ({
  dateInMilliseconds,
  americanDateFormatEnabled,
  t,
  timezone,
  timezoneCookieEnabled
}: {
  dateInMilliseconds: number;
  americanDateFormatEnabled: boolean;
  t: TFunction;
  timezone: string;
  timezoneCookieEnabled: boolean;
}) => {
  const date = applyTimezone(new Date(dateInMilliseconds), timezone, timezoneCookieEnabled);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const hoursToDisplay = hours > 9 ? hours : `0${hours}`;
  const minutesToDisplay = minutes > 9 ? minutes : `0${minutes}`;

  if (americanDateFormatEnabled) {
    return `${t(
      `dates.shortMonth.${date.getMonth()}`
    )} ${date.getDate()}, ${date.getFullYear()} ${hoursToDisplay}:${minutesToDisplay}`;
  }

  return `${date.getDate()} ${t(
    `dates.shortMonth.${date.getMonth()}`
  )} ${date.getFullYear()} ${hoursToDisplay}:${minutesToDisplay}`;
};

const getIsRacingSport = (sportId: string) => {
  return (
    sportId === SportIds.HORSE_RACING ||
    sportId === SportIds.TODAY_HORSE_RACING ||
    sportId === SportIds.GRAY_HOUND_RACING ||
    sportId === SportIds.TODAY_GRAY_HOUND_RACING
  );
};

const AccountStatementItemMobileDetails = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);
  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const alternativeLayOdds = useSelector(getMobileSettingsAlternativeLayOdds);
  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.ALL_ACCOUNT_STATEMENT));
  const data = useSelector(getMobileStatementItemDetails) as TStatementContentItem;
  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const swapColorsFancyMarketsOnCricket = useSelector(getMobileSwapColorsFancyMarketsOnCricket);

  const isAsianViewTable = accountProducts?.asianView && cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const currencySettings = { noRounding: true, isCheckIndian: true, noSymbol: !isMultiCurrencySupported };

  const { noFormattedAmount: stake } = useFormatCurrency(data.size || 0, data.currency, currencySettings);
  const { noFormattedAmount: debit } = useFormatCurrency(data.debit || 0, data.currency, currencySettings);
  const { noFormattedAmount: credit } = useFormatCurrency(data.credit || 0, data.currency, currencySettings);
  const { noFormattedAmount: balance } = useFormatCurrency(data.balance || 0, data.currency, currencySettings);
  const { noFormattedAmount: liability } = useFormatCurrency(data.liability || 0, data.currency, currencySettings);

  const getName = () => {
    if (data.betType === GAME) {
      return data.sportName;
    }

    if (data.outright) {
      return data.competitionName;
    }

    return data.eventName;
  };

  const getBottomName = () => {
    if (data.betType === GAME || data.outright) {
      return null;
    }

    if (data.asian || !getIsRacingSport(data.eventTypeId)) {
      return <p className={styles.details__name__bottom}>{data.competitionName}</p>;
    }

    return null;
  };

  const originLabel = getOriginLabel({ data, t });
  const isNotTransaction = data.status !== StatementDataStatuses.TRANSACTION;
  const { isBackIndicator, isLayIndicator } = getAccountStatementIndicators({ data, swapColorsFancyMarketsOnCricket });

  const getAlternativeOdds = () => {
    if (data.side === BetSides.Lay) {
      if (data.bettingType === BETTING_TYPES.line) {
        return `@${lineAvgPriceFlatValue}`;
      }

      return `@${data.alternativeBackOdds}`;
    }

    return '--';
  };

  const rows = [
    {
      label: `${t(`asianView.labels.oddsType.short.decimal`)} ${t('account.mybets.labels.odds')}`,
      value: `@${data.avgPriceRounded}`,
      isBorder: false,
      isVisible: isAsianViewTable && isNotTransaction
    },
    {
      label: t(data.side === BetSides.Lay ? 'account.statement.labels.backOdds' : 'account.statement.labels.layOdds'),
      value: getAlternativeOdds(),
      isBorder: false,
      isVisible: !isAsianViewTable && isNotTransaction && alternativeLayOdds
    },
    { label: t('account.statement.labels.stake'), value: stake, isBorder: false, isVisible: isNotTransaction },
    {
      label: data.asian ? t('account.statement.labels.risk') : t('account.mybets.labels.liability'),
      value: liability,
      isBorder: false,
      isVisible: isAsianViewTable && isNotTransaction
    },
    {
      label: t('account.statement.labels.balanceChange'),
      value: '',
      isBorder: walletIntegrationType === SINGLE_WALLET,
      isVisible: true,
      type: 'balanceChange'
    },
    {
      label: t('account.statement.labels.balance'),
      value: balance,
      isBorder: true,
      isVisible: walletIntegrationType === SEPARATE_WALLET
    },
    {
      label: t('account.statement.labels.settled'),
      value: getFormattedDate({
        dateInMilliseconds: data.settledDate,
        t,
        americanDateFormatEnabled,
        timezone,
        timezoneCookieEnabled
      }),
      isBorder: false,
      isVisible: true
    },
    {
      label: t('account.statement.labels.placed'),
      value: isNotTransaction
        ? getFormattedDate({
            dateInMilliseconds: data.placedDate,
            t,
            americanDateFormatEnabled,
            timezone,
            timezoneCookieEnabled
          })
        : '',
      isBorder: false,
      isVisible: isNotTransaction
    },
    {
      label: t('account.mybets.labels.type'),
      value: data.inPlay ? t('account.mybets.labels.livePlacement') : t('account.statement.labels.prePlayPlacement'),
      isBorder: false,
      isVisible: isAsianViewTable && isNotTransaction
    },
    { label: t('account.statement.labels.id'), value: data.refId, isBorder: false, isVisible: true, type: 'refId' }
  ].filter(({ isVisible }) => isVisible);

  const isWon = data.status === StatementDataStatuses.WON || data.status === StatementDataStatuses.HALF_WON;
  const isLost = data.status === StatementDataStatuses.LOST || data.status === StatementDataStatuses.HALF_LOST;

  const getMarketName = () => {
    if (data.betType === GAME) {
      return data.gameIdLabel;
    }

    if (data.raceName) {
      return `${parseMillisecondsToTime(data.marketStartTime, timezone, timezoneCookieEnabled)} ${data.raceName} - ${
        data.marketName
      }`;
    }

    return data.marketName;
  };

  const getOddsValue = () => {
    if (data.asian && data.oddsType) {
      return convertOdds(data.avgPrice, data.oddsType);
    }

    return data.bettingType === BETTING_TYPES.line ? lineAvgPriceFlatValue : data.avgPrice;
  };

  return (
    <>
      {!data.asian && alternativeLayOdds && data.status !== StatementDataStatuses.TRANSACTION && (
        <div className={styles.details__infoMsg}>
          <i className={classNames('biab_custom-icon-info-circle', styles.details__infoMsg__icon)} />
          <p className={styles.details__infoMsg__label}>{t('account.mybets.messages.alternativeLayOdds')}</p>
        </div>
      )}
      {!data.depositWithdrawal && (
        <div className={styles.details__topBlock}>
          <p className={styles.details__name}>{getName()}</p>
          {getBottomName()}
        </div>
      )}
      <div
        className={classNames(styles.details__selectionBlock, accountPagesBranding.BET_INDICATOR, {
          [styles.details__selectionBlock__back]: isBackIndicator,
          [styles.details__selectionBlock__lay]: isLayIndicator,
          [styles.details__selectionBlock__mt]: data.depositWithdrawal && data.asian && !alternativeLayOdds,
          [styles.details__selectionBlock__depositWithdrawal]: data.depositWithdrawal,
          [styles.details__selectionBlock__commission]:
            !data.depositWithdrawal && data.status === StatementDataStatuses.TRANSACTION,
          [accountPagesBranding.BET_SIDE_BACK]: isBackIndicator,
          [accountPagesBranding.BET_SIDE_LAY]: isLayIndicator
        })}
      >
        <div className={styles.details__selectionBlock__left}>
          <p className={styles.details__selectionBlock__name}>{getSelectionName({ data, t })}</p>
          {originLabel && <p className={styles.details__selectionBlock__origin}>{originLabel}</p>}
          {!data.depositWithdrawal && <p className={styles.details__selectionBlock__marketName}>{getMarketName()}</p>}
          {(getIsRacingSport(data.eventTypeId) || data.eventTypeId === SportIds.GOLF) &&
            data.eachWayDivisor &&
            typeof data.numberOfWinners === 'number' && (
              <p className={styles.details__selectionBlock__eachWay}>
                {t('market.each.way.termsNoPref', { odds: data.eachWayDivisor, places: data.numberOfWinners })}
              </p>
            )}
          <p className={styles.details__selectionBlock__status}>
            <span
              className={classNames(styles.details__selectionBlock__status__dot, accountPagesBranding.BET_STATUS, {
                [styles.details__selectionBlock__status__label__won__dot]: isWon,
                [styles.details__selectionBlock__status__label__lost__dot]: isLost,
                [accountPagesBranding.POSITIVE]: isWon,
                [accountPagesBranding.NEGATIVE]: isLost
              })}
            />
            <span
              className={classNames(styles.details__selectionBlock__status__label, accountPagesBranding.BET_STATUS, {
                [styles.details__selectionBlock__status__label__won]: isWon,
                [styles.details__selectionBlock__status__label__lost]: isLost,
                [accountPagesBranding.POSITIVE]: isWon,
                [accountPagesBranding.NEGATIVE]: isLost
              })}
            >
              {getAccountStatementStatusLabel({ status: data.status, t })}
            </span>
          </p>
          {data.triggeredByCashOut && data.status !== StatementDataStatuses.TRANSACTION && data.betType !== GAME && (
            <p className={classNames(styles.details__selectionBlock__cashOut, accountPagesBranding.CASH_OUT_LABEL)}>
              {t('market.cashOut')}
            </p>
          )}
        </div>
        {data.status !== StatementDataStatuses.TRANSACTION && (
          <div className={styles.details__selectionBlock__right}>
            <p className={styles.details__selectionBlock__price}>
              @{getOddsValue()}
              {data.asian ? ` (${t(`asianView.labels.betslip.oddsType.${data.oddsType}`)})` : ''}
            </p>
            <p className={styles.details__selectionBlock__side}>
              {t(betsSideValue({ item: data, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver }))}
            </p>
          </div>
        )}
      </div>
      {rows.map(({ label, value, isBorder, type }) => {
        let valueContent = value;

        if (type === 'balanceChange') {
          if (Number(data.credit) > 0) {
            valueContent = `+${credit}`;
          } else if (Number(data.debit) > 0) {
            valueContent = `-${debit}`;
          } else {
            valueContent = credit;
          }
        }

        return (
          <div
            key={label + value}
            className={classNames(styles.details__row, {
              [styles.details__row__border]: isBorder
            })}
          >
            <p className={styles.details__row__label}>{label}</p>
            <p
              className={classNames(styles.details__row__value, {
                [styles.details__row__value__green]: type === 'balanceChange' && Number(data.credit) > 0,
                [styles.details__row__value__red]: type === 'balanceChange' && Number(data.debit) > 0
              })}
            >
              {valueContent}
              {type === 'refId' && (
                <button
                  onClick={() => navigator.clipboard.writeText(valueContent)}
                  className={styles.details__row__value__icon__copyWrapper}
                >
                  <i className={classNames('biab_custom-icon-copy-24', styles.details__row__value__icon)} />
                </button>
              )}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default AccountStatementItemMobileDetails;

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CookieNames } from 'constants/app';
import { ASIAN_VIEW_BETTING_DAY } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import MobileSelect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileSelect';
import { setMobileFilters } from 'redux/modules/myBets';
import { TimeFormats } from 'types';
import { TMobileSelectOption } from 'types/myBets';
import { convertTimeFormatToDropdown } from 'utils';

const MobileFiltersTimeDisplayFormat = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [cookies] = useCookies([CookieNames.TIME_FORMAT]);

  const { translationKey } = useTooltip(ASIAN_VIEW_BETTING_DAY);
  const convertedData = convertTimeFormatToDropdown(t, translationKey);

  const currentTimeFormat = cookies[CookieNames.TIME_FORMAT] || TimeFormats.LOCAL_TIME;

  const timeFormatDropdownOptions: TMobileSelectOption[] = convertedData.map(option => {
    const { id, label, tooltip } = option;
    return {
      id: String(id),
      label: label ?? '',
      tooltip: tooltip ?? '',
      isSelected: String(id) === currentTimeFormat,
      defaultIsSelected: String(id) === currentTimeFormat
    };
  });

  const changeTimeDisplayFormatHandler = (
    optionsChanged: TMobileSelectOption[],
    isTimeDisplayFormatChanged: boolean
  ) => {
    dispatch(
      setMobileFilters({
        timeDisplayFormat: optionsChanged.find(({ isSelected }) => isSelected)?.id ?? '',
        isTimeDisplayFormatChanged
      })
    );
  };

  return (
    <MobileSelect
      title={t('asianView.labels.account.timeDisplayFormat')}
      defaultOptions={timeFormatDropdownOptions}
      onChangeCallback={changeTimeDisplayFormatHandler}
    />
  );
};

export default MobileFiltersTimeDisplayFormat;

import { isString, isUndefined, toNumber } from 'lodash';

import { BetsStatusesTypes, CurrentBetActions } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { BETSLIP_UNIQ_SELECTED_BET_SEPARATOR } from 'constants/betslip';
import { GameMarketTypes } from 'constants/games';
import { BettingTypes, RunnersStatuses } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import { EXPOSURE_LIMIT_ERROR_CODE, LOSS_LIMIT_ERROR_CODE } from 'constants/placement';
import { TSelectedBet } from 'redux/modules/betslip/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TMarketResponse } from 'redux/modules/market/type';
import { TPlacementError } from 'redux/modules/placement/type';
import { PageBlock, PlacementPage } from 'types';
import { MatchType } from 'types/bets';
import { TBetslipMarketPrices, TSelectedBetKeyFields } from 'types/betslip';
import { IMarket, TMarketPrice } from 'types/markets';
import { BetSide } from 'types/myBets';
import { calculateLiability } from 'utils/liability';
import BetActions from 'components/Betslip/components/OpenedBet/components/BetActions';

export const valueToNumber = (value: string) => {
  return value
    .trim()
    .replace(/[^0-9.]/g, '')
    .replace(/\.\./g, '.');
};

export const isLineBettingType = (bettingType: string) => bettingType === BettingTypes.LINE;

export const isHandicapBettingType = (bettingType: string) => {
  return (
    bettingType === BettingTypes.ASIAN_HANDICAP_DOUBLE_LINE || bettingType === BettingTypes.ASIAN_HANDICAP_SINGLE_LINE
  );
};

export const isCombinedTotalMarketType = (marketType: string) => marketType === MARKET_TYPES.combinedTotal;

export const isTotalPointsLine = (marketType: string) => marketType === MARKET_TYPES.totalPointsLine;

export const isEachWayMarketType = (marketType: string) => marketType === MARKET_TYPES.eachWay;

export const isGameMarketType = (marketType: string) => {
  return (
    marketType === GameMarketTypes.ANY_NUMBER_OF_WINNERS ||
    marketType === GameMarketTypes.WIN_ONLY ||
    marketType === GameMarketTypes.SINGLE_WINNER_OR_TIE ||
    marketType === GameMarketTypes.VARIABLE_HANDICAP
  );
};

export const getBestPrices = ({
  marketPrices,
  selectionId,
  handicap,
  type
}: {
  marketPrices?: TBetslipMarketPrices | null;
  selectionId: number | undefined;
  handicap?: number | string | null;
  type: BetSide;
}) => {
  const selectionPrices =
    marketPrices?.rc?.find(({ id, hc }: { id: number; hc?: number | string | null }) => {
      return id === selectionId && +(hc || 0) === +(handicap || 0);
    }) ?? null;

  return selectionPrices?.[type === BetSides.Back ? 'bdatb' : 'bdatl']?.[0] ?? null;
};

// Parse current bet fields to number values
export const parseCurrentBet = (bet: TCurrentBet) => {
  return {
    ...bet,
    ...{
      ...[
        'alternativeBackOdds',
        'alternativeBackOddsRounded',
        'averagePrice',
        'averagePriceRounded',
        'liability',
        'pastTotalLiability',
        'potentialProfit',
        'price',
        'profit',
        'profitNet',
        'size',
        'sizeCancelled',
        'sizeLapsed',
        'sizeMatched',
        'sizePlaced',
        'sizeRemaining',
        'sizeVoided',
        'totalWinnings'
      ].reduce((result, item) => {
        return { ...result, ...{ [item]: +(bet[item as keyof TCurrentBet] || 0) } };
      }, {})
    }
  };
};

export const getBestPriceFromMarketPrices = (
  marketPrice: TMarketPrice | null | undefined,
  marketType: string,
  bettingType: string
) => {
  return marketPrice && marketPrice.odds && isLineBettingType(bettingType) && !isTotalPointsLine(marketType)
    ? marketPrice?.odds || 0
    : marketPrice?.odds ?? 0;
};

export const formatBestPrice = (price: number | undefined, marketType: string, bettingType: string) => {
  return price && isLineBettingType(bettingType) && !isTotalPointsLine(marketType) ? price || 0 : price || 0;
};

export const isResponsibleGamblingError = (error: TPlacementError | string) =>
  !isString(error) && [EXPOSURE_LIMIT_ERROR_CODE, LOSS_LIMIT_ERROR_CODE].includes(error?.response?.data?.id ?? '');

/**
 * Set bets for all runners by type (BACK OR LAY)
 * @param market
 * @param marketPrices
 * @param type
 * @param pageBlock
 * @param page
 * @param raceName
 * @param isDefaultFormat
 * @param minAmountToShowOdds
 */
export const getAllBetsFromMarketByType = ({
  market,
  marketPrices,
  type,
  pageBlock,
  page,
  raceName,
  isDefaultFormat,
  minAmountToShowOdds = 0
}: {
  market: IMarket | TMarketResponse;
  marketPrices: TBetslipMarketPrices;
  type: BetSide;
  pageBlock: PageBlock;
  page: PlacementPage;
  raceName?: string;
  isDefaultFormat?: boolean;
  minAmountToShowOdds?: number;
}) => {
  const bets = market.runners.reduce((res: TSelectedBet[], { selectionId, handicap, runnerName, lineSide }) => {
    const marketPricesRunner = marketPrices?.marketDefinition?.runners?.find(
      marketPrice => (marketPrice.handicap ? marketPrice.handicap === handicap : true) && marketPrice.id === selectionId
    );

    const rc = marketPrices?.rc?.find(
      runner => (runner.hc ? runner.hc === handicap : true) && runner.id === selectionId
    );

    const bestPrices = getBestPrices({ marketPrices, selectionId, handicap, type });

    const odds = getBestPriceFromMarketPrices(
      bestPrices,
      market.description.marketType,
      market.description.bettingType
    );

    const roundedAmount = isDefaultFormat ? Math.floor(bestPrices?.amount ?? 0) : Math.ceil(bestPrices?.amount ?? 0);

    const isOddsEnableToShow = roundedAmount > minAmountToShowOdds;

    return marketPricesRunner?.status === RunnersStatuses.ACTIVE && !rc?.locked
      ? [
          ...res,
          {
            marketId: market.marketId,
            sportId: market.eventType.id,
            marketType: market.description.marketType,
            bettingType: market.description.bettingType,
            numberOfWinners: market.numberOfWinners,
            eventName: market.event.name,
            marketName: market.marketName,
            selectionName: runnerName,
            marketUnit: market.description.lineRangeInfo?.marketUnit ?? '',
            lineSide: lineSide ?? null,
            marketStartTime: market.marketStartTime,
            eachWayDivisor: market.description.eachWayDivisor,
            lineRangeInfo: market.description.lineRangeInfo,
            priceLadderDescription: market.description.priceLadderDescription,
            fancyView: market.fancyView,
            currency: marketPrices?.currency ?? '',
            price: isOddsEnableToShow ? odds : '',
            raceName,
            selectionId,
            handicap,
            type,
            pageBlock,
            isBetAll: true,
            page
          }
        ]
      : res;
  }, []);

  return bets.reverse();
};

export const getIsPartiallyMatched = ({ sizeMatched, sizeRemaining }: TCurrentBet) =>
  toNumber(sizeMatched) > 0 && toNumber(sizeRemaining) > 0;

export const getIsFullyMatched = ({ sizeMatched, sizeRemaining, sizeCancelled }: TCurrentBet) =>
  toNumber(sizeMatched) > 0 && toNumber(sizeRemaining) === 0 && toNumber(sizeCancelled) === 0;

export const isCancelled = ({ offerState, sizeRemaining, sizeCancelled }: TCurrentBet) =>
  offerState === BetsStatusesTypes.CANCELLED ||
  (offerState === BetsStatusesTypes.MATCHED && +sizeRemaining === 0 && +sizeCancelled > 0);

export const getSelectedBetKey = ({ marketId, selectionId, handicap }: TSelectedBetKeyFields) => {
  return `${marketId}_${selectionId}_${handicap ?? 0}`;
};

export const getUniqSelectedBetKey = ({ marketId, selectionId, handicap, betslipType }: TSelectedBetKeyFields) => {
  return `${marketId}_${selectionId}_${handicap ?? 0}${BETSLIP_UNIQ_SELECTED_BET_SEPARATOR}${new Date().getTime()}${
    betslipType ? `_${betslipType}` : ''
  }`;
};

export const checkUniqSelectedBetKey = ({
  betKey,
  marketId,
  selectionId,
  handicap
}: {
  betKey: string;
  marketId: string;
  selectionId: number;
  handicap?: string | number | null;
}) => {
  return betKey.split(BETSLIP_UNIQ_SELECTED_BET_SEPARATOR)[0] === `${marketId}_${selectionId}_${handicap ?? 0}`;
};

export const countOfferStatuses = ({
  offers,
  numberOfErrors,
  isPNCEnabled,
  isGameBetSlip
}: {
  offers: TCurrentBet[];
  numberOfErrors: number;
  isPNCEnabled: boolean;
  isGameBetSlip: boolean;
}) => {
  const statusesCounts = {
    numberOfCancelled: 0,
    numberOfPartiallyMatched: 0,
    numberOfMatched: 0,
    numberOfUnmatched: 0,
    numberOfBetErrors: numberOfErrors,
    numberOfPlacedWithBetterOdds: 0
  };

  offers.forEach(offer => {
    const isPlacedBack = offer.side === BetSides.Back;
    const isPlacedWithBetterOdds =
      isPNCEnabled &&
      !isGameBetSlip &&
      offer.offerState === BetsStatusesTypes.MATCHED &&
      (isPlacedBack ? offer.averagePrice > offer.price : offer.averagePrice < offer.price);

    if (offer.sizeCancelled) {
      statusesCounts.numberOfCancelled++;
    } else if (isPlacedWithBetterOdds) {
      statusesCounts.numberOfPlacedWithBetterOdds++;
    } else if (offer.sizeRemaining && offer.sizeMatched) {
      statusesCounts.numberOfPartiallyMatched++;
    } else if (!offer.sizeRemaining && offer.sizeMatched) {
      statusesCounts.numberOfMatched++;
    } else if (offer.sizeRemaining && !offer.sizeMatched) {
      statusesCounts.numberOfUnmatched++;
    } else {
      statusesCounts.numberOfBetErrors++;
    }
  });

  return statusesCounts;
};

export const getBetNotificationStatusKey = ({
  isError,
  isPlacing,
  isMatchedBet,
  isPartiallyMatchedBet,
  isUnmatchedBet,
  isCancelledBet,
  isPNCEnabled,
  isPlacedWithBetterOdds,
  isGameBetSlip
}: {
  isError: boolean;
  isPlacing: boolean;
  isMatchedBet: boolean;
  isPartiallyMatchedBet: boolean;
  isUnmatchedBet: boolean;
  isCancelledBet: boolean;
  isPNCEnabled: boolean;
  isPlacedWithBetterOdds: boolean;
  isGameBetSlip: boolean;
}) => {
  const messageConditions = [
    { condition: isPlacing, message: 'betslip.labels.placing' },
    { condition: isPlacedWithBetterOdds, message: 'betslip.labels.pnc.placedWithBetterOdds' },
    { condition: isMatchedBet && isPNCEnabled && !isGameBetSlip, message: 'betslip.labels.pnc.placed' },
    { condition: isMatchedBet, message: 'betslip.labels.matchedBet' },
    { condition: isPartiallyMatchedBet, message: 'betslip.labels.partiallyMatchedBet' },
    { condition: isUnmatchedBet, message: 'betslip.labels.unmatchedBet' },
    { condition: isCancelledBet && !isPNCEnabled, message: 'betslip.labels.cancelledBet' },
    {
      condition:
        (isCancelledBet && isPNCEnabled) ||
        (isError && !isCancelledBet) ||
        (!isPlacing && !isPartiallyMatchedBet && !isMatchedBet && !isUnmatchedBet && !isCancelledBet),
      message: 'betslip.labels.error'
    },
    { condition: true, message: 'betslip.labels.placing' } // default case
  ];

  return messageConditions.find(item => item.condition)?.message;
};

export const getCurrentBetPrice = (bet: TCurrentBet) =>
  !isUndefined(bet.changedPrice) ? bet.changedPrice : bet.averagePriceRounded || bet.price;

export const getCurrentBetSize = (matchType: MatchType, bet: TCurrentBet) => {
  if (
    matchType === MatchTypes.UNMATCHED &&
    (bet.offerState === BetsStatusesTypes.CANCELLED || bet.action === CurrentBetActions.CANCELLING) &&
    Number(bet.sizeCancelled) > 0
  ) {
    return !isUndefined(bet.changedSize) ? bet.changedSize : bet.sizeCancelled;
  }

  if (
    matchType === MatchTypes.MATCHED ||
    (bet.offerState === BetsStatusesTypes.MATCHED && !Number(bet.sizeRemaining))
  ) {
    return bet.sizeMatched;
  }

  if (bet.offerState === BetsStatusesTypes.LAPSED) {
    return bet.sizeLapsed;
  }

  if (isCancelled(bet) && bet.action !== CurrentBetActions.EDITING) {
    return bet.sizeCancelled;
  }

  return !isUndefined(bet.changedSize) ? bet.changedSize : bet.sizeRemaining;
};

export const getCurrentBetProfit = (matchType: MatchType, bet: TCurrentBet) => {
  if (
    matchType === MatchTypes.MATCHED ||
    (bet.offerState === BetsStatusesTypes.MATCHED && !Number(bet.sizeRemaining) && !Number(bet.sizeCancelled))
  ) {
    return bet.side === BetSides.Back ? bet.profitNet : bet.liability;
  } else {
    const { marketType, bettingType, eachWayDivisor, side: betType } = bet;

    return !isUndefined(bet.changedProfit)
      ? bet.changedProfit
      : calculateLiability(bet.price, getCurrentBetSize(matchType, bet), {
          marketType,
          bettingType,
          eachWayDivisor,
          betType
        });
  }
};

export const getIsSelectedBetValid = (bet: TSelectedBet): boolean => {
  return bet.isPriceValid !== false && bet.isSizeValid !== false;
};

export const getIsSelectedBetActive = (bet: TSelectedBet): boolean => {
  return getIsSelectedBetValid(bet) && !bet.isDisabled;
};

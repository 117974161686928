import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ASIAN_TABS_LIVE_PREFIX } from 'constants/app';
import { ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, ASIAN_VIEW_PAGE_PARAM, AsianViewTimeFrames } from 'constants/asianView';
import { asianViewNavigation as branding } from 'constants/branding';
import { ASIAN_BASE_URL, ASIAN_VIEW_CASH_OUT_URL } from 'constants/locations';
import useDeleteAsianViewSearchParams from 'hooks/useDeleteAsianViewSearchParams';
import { getIsDesktopInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import {
  getFirstNavigationMarketLinkBySportId,
  getNavigationCountersBySportId
} from 'redux/modules/asianSportsNavigation/selectors';
import { resetContent, setDaysValue } from 'redux/modules/asianView';
import { getAsianViewDaysValue } from 'redux/modules/asianView/selectors';
import { closeProfitLossTablePopUp } from 'redux/modules/profitLossTablePopUp';

import styles from '../../../../styles.module.scss';

interface ITab {
  title: string;
  selected: boolean;
  identifier: string;
  sportId: string;
  isCashOutPage?: boolean;
}

const Tab = ({ title, selected, identifier, sportId, isCashOutPage = false }: ITab) => {
  const dispatch = useDispatch();
  const { marketLink, timeFilter } = useParams();
  const [searchParams] = useSearchParams();

  const daysValue = useSelector(getAsianViewDaysValue);
  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);
  const counters = useSelector(getNavigationCountersBySportId(sportId));
  const firstMarketLink = useSelector(getFirstNavigationMarketLinkBySportId(sportId));

  const { removeAsianViewSearchParams } = useDeleteAsianViewSearchParams();

  const pageParam = isInfiniteScrollEnabled ? '' : `${ASIAN_VIEW_PAGE_PARAM}=0`;
  const betSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);
  const betSlipCashOutPageParam = betSlipCashOutPage
    ? `${pageParam ? '&' : ''}${ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE}=${betSlipCashOutPage}`
    : '';
  const linkPathname = isCashOutPage
    ? `${ASIAN_VIEW_CASH_OUT_URL}/${identifier}`
    : `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${identifier}/marketLink/${marketLink ?? firstMarketLink}`;

  const handleClickTab = (event: SyntheticEvent<HTMLAnchorElement>) => {
    if (timeFilter === identifier) {
      event.preventDefault();
      removeAsianViewSearchParams();
    } else {
      dispatch(resetContent());
      if (daysValue !== AsianViewTimeFrames.ALL) {
        dispatch(setDaysValue(AsianViewTimeFrames.ALL));
      }
    }

    if (timeFilter !== identifier) {
      dispatch(closeProfitLossTablePopUp());
    }
  };

  return (
    <Link
      to={{
        pathname: linkPathname,
        search: `${pageParam}${betSlipCashOutPageParam}`
      }}
      onClick={handleClickTab}
      className={classNames(styles.tab, branding.NAVIGATION_TAB, {
        [styles.selected]: selected,
        [branding.SELECTED]: selected
      })}
    >
      {title}
      {identifier === ASIAN_TABS_LIVE_PREFIX && (
        <div className={classNames(styles.live, branding.LIVE_EVENTS_COUNTER)}>{counters?.numberOfInPlayEvents}</div>
      )}
    </Link>
  );
};

export default Tab;

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Loader, { CircleColors } from 'components/Loader';
import { GAME } from 'constants/app';
import { accountPagesBranding, betslipBranding, mobileComponents } from 'constants/branding';
import { GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { BettingTypes } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import useAccountMyBetsCancelAction from 'hooks/useAccountMyBetsCancelAction';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import {
  getDisplayCurrencySymbol,
  getIsCurrencySymbolAfterAmount,
  getPNCEnabledSetting,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { setMobileEditOfferId } from 'redux/modules/myBets';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { getUserInfoSymbol } from 'redux/modules/user/selectors';
import { OfferStates, TOfferState } from 'types/myBets';
import { isLineBettingType } from 'utils/betslip';
import { parseMillisecondsToTime } from 'utils/date';
import {
  getBetSideLabel,
  getStatusTranslationKey,
  oddsValue,
  roundedPriceLineBet,
  shouldSwapColors
} from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

type TMobileContentItemProps = {
  bet: TMyBetsContentItem;
  openMobileBetDetails: (bet: TMyBetsContentItem) => void;
};

const MobileContentItem = ({ bet, openMobileBetDetails }: TMobileContentItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const timezone = useSelector(getTimezone);
  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const currencySymbolAfterAmount = useSelector(getIsCurrencySymbolAfterAmount);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const userInfoSymbol = useSelector(getUserInfoSymbol);

  const { isMultiCurrencySupported, currencies } = useMultiCurrencySupporting();
  const { isCancelBetSuccess, isCancelBetLoading, isCancelBetError, onCancelBet } = useAccountMyBetsCancelAction({
    bet,
    isMobile: true
  });

  const { noFormattedAmount: stake } = useFormatCurrency(bet?.size || 0, bet?.currency, {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: isMultiCurrencySupported
  });

  let currencySymbol: string;

  if (isMultiCurrencySupported) {
    currencySymbol = currencies.find(({ currencyCode }) => currencyCode === bet.currency)?.symbol ?? '';
  } else {
    currencySymbol = displayCurrencySymbol ? userInfoSymbol || '$' : '';
  }

  const formattedCurrency = (amount: string) => {
    const currency = currencySymbolAfterAmount ? `${amount}${currencySymbol}` : `${currencySymbol}${amount}`;
    return currency.replace('-', '');
  };

  const formattedProfit = (amount: string) => {
    return `${+amount > 0 ? '+' : +amount < 0 ? '-' : ''}${formattedCurrency(Math.abs(+amount).toString())}`;
  };

  const isCurrentPeriod = bet.offerState === OfferStates.MATCHED || bet.offerState === OfferStates.PLACED;
  const isPastPeriod = (
    [OfferStates.WON, OfferStates.LOST, OfferStates.CANCELLED, OfferStates.LAPSED, OfferStates.VOIDED] as TOfferState[]
  ).includes(bet.offerState);
  const isWon = bet.offerState === OfferStates.WON;
  const isLost = bet.offerState === OfferStates.LOST;
  const isSettled = isWon || isLost;
  const isCancelled = bet.offerState === OfferStates.CANCELLED;
  const isMatched = bet.offerState === OfferStates.MATCHED;
  const isUnmatched = bet.offerState === OfferStates.PLACED;
  const isLapsed = bet.offerState === OfferStates.LAPSED;
  const isVoided = bet.offerState === OfferStates.VOIDED;

  const isBack = bet.side === BetSides.Back;

  const backColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket) ? !isBack : isBack;
  const layColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket) ? isBack : !isBack;

  const placementLabel = t(`account.mybets.labels.${bet.inPlay ? 'live' : 'prePlay'}Placement`);
  const roundedOdds = roundedPriceLineBet(bet);

  const isGame = bet.betType === BettingTypes.GAME;
  const marketName = isGame
    ? ''
    : bet.raceName
    ? ` - ${parseMillisecondsToTime(bet.marketStartDate, timezone, timezoneCookieEnabled)} ${bet.raceName} - ${
        bet.marketName
      }`
    : ` - ${bet.marketName}`;

  const isOfferStateIcon = !bet.asian && (isMatched || isUnmatched);

  const isLineMarket = isLineBettingType(bet.bettingType);
  const selectionNameTpl = `${bet.selectionName} ${isLineMarket ? roundedPriceLineBet(bet) : ''}`;

  return (
    <div
      className={classNames(styles.bet, accountPagesBranding.BET_INDICATOR, {
        [styles.bet__back]: backColors && !isCancelBetSuccess,
        [styles.bet__lay]: layColors && !isCancelBetSuccess,
        [accountPagesBranding.BET_SIDE_BACK]: backColors && !isCancelBetSuccess,
        [accountPagesBranding.BET_SIDE_LAY]: layColors && !isCancelBetSuccess
      })}
      onClick={() => {
        if (!isUnmatched || (!isCancelBetLoading && !isCancelBetSuccess)) {
          openMobileBetDetails(bet);
        }
      }}
    >
      <div className={styles.content}>
        <div className={styles.content__top}>
          <div className={styles.content__left}>
            <span className={styles.selectionNameWrap}>
              {isOfferStateIcon && (
                <i
                  className={classNames({
                    ['biab_custom-icon-unmatched-24']: isUnmatched,
                    ['biab_custom-icon-success-circle']: isMatched,
                    [styles.icon__unmatched]: isUnmatched,
                    [styles.icon__matched]: isMatched
                  })}
                >
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </i>
              )}
              <span className={styles.selectionName}>{selectionNameTpl}</span>
            </span>
            <span className={styles.eventName}>
              {bet.eventName}
              {marketName}
            </span>
            {isGame && (
              <span>
                {t('games.gameId')}: {bet.marketId}
              </span>
            )}
            {bet.marketType === MARKET_TYPES.eachWay && (
              <span className={styles.capitalize}>
                {t('market.each.way.termsNoPref', { odds: bet.eachWayDivisor, places: bet.numberOfWinners })}
              </span>
            )}
            {isCurrentPeriod && (
              <span>
                Risk <span className={styles.riskToWinValue}>{formattedCurrency(bet.liability)}</span> to win{' '}
                <span className={styles.riskToWinValue}>{formattedCurrency(bet.potentialProfit)}</span>
              </span>
            )}
            {isPastPeriod && (
              <span className={classNames(styles.status)}>
                <i
                  className={classNames('biab_custom-icon-dot', styles.icon__dot, accountPagesBranding.BET_STATUS, {
                    [accountPagesBranding.POSITIVE]: isWon,
                    [accountPagesBranding.NEGATIVE]: isLost,
                    [accountPagesBranding.NEUTRAL]: isCancelled || isLapsed || isVoided
                  })}
                />
                <span
                  className={classNames(accountPagesBranding.BET_STATUS, {
                    [accountPagesBranding.POSITIVE]: isWon,
                    [accountPagesBranding.NEGATIVE]: isLost,
                    [accountPagesBranding.NEUTRAL]: isCancelled || isLapsed || isVoided
                  })}
                >
                  {t(getStatusTranslationKey(bet.offerState, isPNCEnabled))}
                </span>
                <span
                  className={classNames({
                    [styles.status__won]: isWon,
                    [styles.status__lost]: isLost
                  })}
                >
                  {isSettled && formattedProfit(bet.profit)}
                  {isCancelled && formattedCurrency(bet.sizeCancelled)}
                  {isVoided && formattedCurrency(bet.sizeVoided)}
                  {isLapsed && formattedCurrency(bet.sizeLapsed)}
                </span>
              </span>
            )}
            {(bet.triggeredByCashOut || bet.asian) && (
              <span className={styles.row}>
                {bet.triggeredByCashOut && (
                  <span className={classNames(styles.cashOutLabel, accountPagesBranding.CASH_OUT_LABEL)}>
                    {t('account.myBets.labels.cashOut')}
                  </span>
                )}
                {bet.asian && <span>{placementLabel}</span>}
              </span>
            )}
          </div>
          <div className={styles.content__right}>
            <div className={styles.content__right__odds}>
              <span className={styles.content__right__odds__value}>
                @
                {bet.asian
                  ? `${convertOdds(roundedOdds, bet.oddsType)} (${t(
                      `asianView.labels.betslip.oddsType.${bet.oddsType}`
                    )})`
                  : oddsValue(bet, { isRounded: true })}
              </span>
              <span>{t(getBetSideLabel(bet))}</span>
            </div>
            <i
              className={classNames('biab_custom-icon-chevron-right-24', styles.icon__arrow)}
              onClick={() => openMobileBetDetails(bet)}
            />
          </div>
        </div>
      </div>
      {isUnmatched && (
        <div className={styles.unmatchedActions}>
          <button
            className={classNames(styles.unmatchedActions__action, mobileComponents.BUTTON, mobileComponents.SECONDARY)}
            onClick={e => {
              e.stopPropagation();
              onCancelBet();
            }}
          >
            {t('account.mybets.btns.cancelBet')}
          </button>
          <button
            className={classNames(styles.unmatchedActions__action, mobileComponents.BUTTON, mobileComponents.SECONDARY)}
            onClick={e => {
              e.stopPropagation();

              const url = `${bet.betType === BettingTypes.GAME ? GAMES_BASE_URL : SPORT_BASE_URL}/${
                bet.eventTypeId
              }/market/${bet.marketId}`;

              dispatch(setMobileEditOfferId(bet.offerId));

              navigate(url);
            }}
          >
            {t('account.mybets.btns.updateBet')}
          </button>
        </div>
      )}
      {isUnmatched && isCancelBetError && (
        <div className={classNames(styles.error, mobileComponents.NOTIFICATION, mobileComponents.ERROR)}>
          <i
            className={classNames('biab_custom-icon-warning-circle', styles.error__icon, betslipBranding.WARNING_ICON)}
          />
          <p
            className={styles.error__msg}
            dangerouslySetInnerHTML={{ __html: unescape(t('error.code.EX005.message')) }}
          />
        </div>
      )}
      {isUnmatched && isCancelBetLoading && (
        <div className={styles.loader__wrapper}>
          <Loader circleColor={CircleColors.BLUE_2} className={styles.loader} />
        </div>
      )}
      {isUnmatched && isCancelBetSuccess && (
        <div className={classNames(styles.success, mobileComponents.NOTIFICATION, mobileComponents.INFO)}>
          <i className={classNames('biab_custom-icon-info-circle', styles.success__icon, betslipBranding.INFO_ICON)} />
          <div>
            <p className={styles.success__text}>
              {t(`account.mybets.labels.${bet?.side?.toLowerCase()}`)}:{' '}
              <span className={styles.success__text__bold}>{bet?.selectionName}</span> –{' '}
              {bet?.betType === GAME ? bet?.sportName : bet?.marketName}
            </p>
            <p className={styles.success__text}>
              <span className={styles.success__text__bold}>
                {stake} @{bet?.price}
              </span>{' '}
              – {t('account.mybets.labels.cancelled')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileContentItem;
